<template lang="">
    <div>
        
    </div>
    <div class="container" v-if="loading">
    <div class="row">
      <div class="col">
        <Spinner />
      </div>
    </div>
  </div>
</template>
<script>
import { Auth } from '../services/Auth';
import Spinner from '../components/Spinner.vue';
import { useUserStore } from '../stores/userstore';
export default {
  name: 'Logout',
  components: {
    Spinner
  },
  setup() {
    const store = useUserStore();

    return {
      store
    }
  },
  async created() {
    const token = await Auth.logout();
    console.log(token.data);
    // alert('logout.vue');
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("authToken");
    this.store.isLogin = false;
    this.$router.push("/login")

  },
}
</script>
<style lang="">
    
</style>